<template>
    <div class="header p-d-flex">
        <slot></slot>
        <h2 class="p-as-center" v-if="title">{{ title }}</h2>
    </div>
</template>

<script>

export default {
    name: "Header",
    props: ["title"],
}
</script>

<style scoped>
.header {
    padding: 5px;
    background-color: #9ebac1;
    height: 50px;
    color: white;
}

.header .back {
    color: white;
}

.header .p-button {
    margin-top: 4px;
}

.header h2 {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    /*margin-right: 10px;*/
    color: white;
}
</style>