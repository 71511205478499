<template>
    <Header>
        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
    </Header>

    <div class="p-p-3">
        <DataTable
            :rowHover="true"
            responsiveLayout="scroll"
            :loading="loading"
            :value="data"
            :rows="LazyFilters.Limit"
            :sortField="LazyFilters.SortField"
            :sortOrder="LazyFilters.SortOrder"
            :paginator="true"
            :lazy="true"
            :totalRecords="totalRecords"
            showGridlines
            @page="onPage($event)"
            @sort="onPage($event)"
            v-model:filters="LazyFilters.filters"
            filterDisplay="row"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
            <template #header>
                    <h2 class="p-m-0">{{totalRecords}} users</h2>
            </template>

            <template #loading>
                Loading...
            </template>
            <template #empty>
                No records found.
            </template>

            <Column field="Email" header="Email" :showFilterMenu="false" :sortable="true">
                <template #filter>
                    <InputText type="text" v-model="LazyFilters.filters.Email" @keydown.enter="loadData()" class="p-column-filter"/>
                </template>

                <template #body="s">
                    <span v-tooltip.bottom="s.data.Email" class="truncator" >{{ s.data.Email }}</span>
                </template>
            </Column>

            <Column field="Username" header="User Name" :showFilterMenu="false" :sortable="true">

                <template #filter>
                    <InputText type="text" v-model="LazyFilters.filters.Username" @keydown.enter="loadData()" class="p-column-filter"/>
                </template>

                <template #body="s">
                    <span v-tooltip.bottom="s.data.Username" class="truncator">{{ s.data.Username }}</span>
                </template>
            </Column>

            <Column field="AccountShortname" header="Account" :showFilterMenu="false" :sortable="true">

                <template #filter>
                    <InputText type="text" v-model="LazyFilters.filters.AccountShortname" @keydown.enter="loadData()" class="p-column-filter"/>
                </template>

                <template #body="s">
                    <span v-tooltip.bottom="s.data.AccountShortname" class="truncator">{{ s.data.AccountShortname }}</span>
                </template>
            </Column>


            <Column field="AccountName" header="Account Name" :showFilterMenu="false" :sortable="true">

                <template #filter>
                    <InputText type="text" v-model="LazyFilters.filters.AccountName" @keydown.enter="loadData()" class="p-column-filter"/>
                </template>

                <template #body="s">
                    <span v-tooltip.bottom="s.data.AccountName" class="truncator">{{ s.data.AccountName }}</span>
                </template>
            </Column>


            <Column field="Role" header="Role" :showFilterMenu="false" :sortable="true">

                <template #filter>
                    <Dropdown style="max-width:300px" s v-model="LazyFilters.filters.Role" @change="loadData()" :options="roles" :filter="true" :showClear="true"></Dropdown>
                </template>

                <template #body="s">
                    <span style="max-width:300px" class="truncator">{{ s.data.Role?.join(", ") }}</span>
                </template>
            </Column>

            <Column field="Added" header="Added" :showFilterMenu="false" :sortable="true">
                <template #body="s">
                    <span class="truncator">{{printFormat('time', s.data.Added) }}</span>
                </template>
            </Column>

            <Column field="LastLogin" header="Last Login" :sortable="true">
                <template #body="s">
                    <span class="truncator">{{printFormat('time', s.data.LastLogin)}}</span>
                </template>
            </Column>

            <Column field="IsActive" header="Is Active" :sortable="true" :showFilterMenu="false" style="width: 130px">

                <template #filter>
                    <TriStateCheckbox v-model="isActive" @change="loadData()" />
                </template>


                <template #body="s">
                    <span v-if="s.data.IsActive === true"><i class="pi pi-check"></i></span>
                    <span v-else><i class="pi pi-times"></i></span>
                </template>
            </Column>

            <Column field="IsStaff" header="Is Staff" :sortable="true" :showFilterMenu="false" style="width: 130px">

                <template #filter>
                    <TriStateCheckbox v-model="isStaff" @change="loadData()" />
                </template>

                <template #body="s">
                    <span v-if="s.data.IsStaff === true"><i class="pi pi-check"></i></span>
                    <span v-else><i class="pi pi-times"></i></span>
                </template>
            </Column>

            <Column field="IsAdmin" header="Is Admin" :sortable="true" :showFilterMenu="false" style="width: 130px">

                <template #filter>
                    <TriStateCheckbox v-model="isAdmin" @change="loadData()" />
                </template>

                <template #body="s">
                    <span v-if="s.data.IsAdmin === true"><i class="pi pi-check"></i></span>
                    <span v-else><i class="pi pi-times"></i></span>
                </template>
            </Column>

            <Column field="Language" header="Language" :showFilterMenu="false" :sortable="true">

                <template #filter>
                    <InputText type="text" v-model="LazyFilters.filters.Language" @keydown.enter="loadData()" class="p-column-filter"/>
                </template>

                <template #body="s">
                    <span v-tooltip.bottom="s.data.Language" class="truncator">{{ s.data.Language }}</span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import Header from "@/components/common/Header";
import MetricService from "@/services/metrics";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TriStateCheckbox from "primevue/tristatecheckbox";

export default {
    components: {Header, DataTable, Column, TriStateCheckbox},
    name:"GlobalUsers",
    service: null,
    data: function(){
        return {
            roles:[],
            data:[],
            search:null,
            totalRecords: 0,
            loading:true,
            isStaff:null,
            isActive:null,
            isAdmin: null,
            LazyFilters:{
                fields: ["UserID", "AccountShortname", "AccountName", "Email", "Username", "Job", "Role", "Head", "Department", "WorkPhone", "Phone", "Language", "Timezone", "ContentLanguage", "IsActive", "IsDeleted", "IsGuest", "IsStaff", "IsSuperuser", "Added", "LastLogin", "IsAdmin", "Language"],
                Offset: 0,
                page: 1,
                Limit: 20,
                SortField: null,
                SortOrder: null,
                filters: {},
            },
        }
    },
    methods:{
        exportCSV:function() {
            this._prepareFilters()
            this.service.exportGlobalUsers(this.LazyFilters)
        },
        onPage:function(event){
            Object.assign(this.LazyFilters, {
                Offset: event.first,
                page: event.page,
                Limit: event.rows,
                pageCount: event.pageCount,
                SortField: event.sortField,
                SortOrder: event.sortOrder
            })
            this.loadData()
        },
        _prepareFilters(){
            let filters = {}

            for (const [key, value] of Object.entries(this.LazyFilters.filters)) {
                if (key != "IsActive" && key != "IsStaff" && key != "IsAdmin") {filters[key] = value}
            }

            if (this.isActive !== null) {
                filters["IsActive"] = this.isActive
            }

            if (this.isStaff !== null) {
                filters['IsStaff'] = this.isStaff
            }

            if (this.isAdmin !== null) {
                filters['IsAdmin'] = this.isAdmin
            }

            this.LazyFilters.filters = filters
        },
        loadData() {
            this.loading = true
            this._prepareFilters()
            this.service.getGlobalUsers(this.LazyFilters).then(data => {
                this.data = data.users
                this.totalRecords = data.total
                this.roles = data.roles
                this.loading = false
            })
        }
    },
    created() {
        this.service = new MetricService();
        this.loadData();
    },
    mounted() {

    }
}
</script>
