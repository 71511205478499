import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '@/views/Dashboard'
import Details from '@/views/Details'
import GlobalUsers from "@/views/GlobalUsers";
import GlobalCharts from "@/views/GlobalCharts";
import GlobalJira from "@/views/GlobalJira";
import Login from "@/views/Login"
import Logout from "@/views/Logout"
import Users from "@/views/Users"

const routes = [
    {
        path: '/',
        redirect: {name: 'home'}
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        redirect: {name: 'list-users'},
        children: [
            {
                path: '/list',
                component: () => import("@/components/admin/ListUsers"),
                name:'list-users'
            },
            {
                path: ':userid',
                component: () => import("@/components/admin/AddEditUser"),
                name:'edit-user',
                props:true,
            },
            {
                path: 'new',
                component: () => import("@/components/admin/AddEditUser"),
                name:'new-user'

            }
        ]
    },

    {
        path: '/dashboard',
        name: 'home',
        component: Dashboard
    },
    {
        path: '/globalcharts/',
        name: 'globalcharts',
        component: GlobalCharts

    },
    {
        path: '/globalusers/',
        name: 'globalusers',
        component: GlobalUsers
    },
    {
        path: '/globaljira/',
        name: 'globaljira',
        component: GlobalJira
    },
    {
        path: '/details/:account',
        name: 'account',
        component: Details,
        children: [
            {
                path: "pim",
                component: () => import("@/components/details/PimData"),
                name: "pim_overview"
            },
            {
                path: "eci",
                component: () => import("@/components/details/EciData"),
                name: "eci_overview"
            },
            {
                path: "eci-lists",
                component: () => import("@/components/details/EciLists"),
                name: "eci_lists"
            },
            {
                path: "users",
                component: () => import("@/components/details/UsersData"),
                name: "account_users"
            },
            {
                path: "dam",
                component: () => import("@/components/details/DamData"),
                name: "account_dam"
            },
            {
                path: "jira",
                component: () => import("@/components/details/JiraData"),
                name: "account_jira"
            },
            {
                path: "commerce",
                component: () => import("@/components/NotImplemented"),
                name: "account_commerce"
            },
        ]

    },
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})