<template>
    <Header :title="Account" />
    <TabMenu :model="MenuItems" />
    <router-view />
</template>

<script>
import TabMenu from 'primevue/tabmenu';
import Header from "@/components/common/Header";

export default {
    name:"Details",
    components:{
        TabMenu,
        Header
    },
    data() {
        return {
            Account: null,
            MenuItems: [
                {label: 'PIM Metrics', icon: 'pi pi-fw pi-home', to: 'pim'},
                {label: 'ECI Metrics', icon: 'pi pi-fw pi-book', to: 'eci'},
                {label: 'ECI Lists', icon: 'pi pi-fw pi-book', to: 'eci-lists'},
                {label: 'Users', icon: 'pi pi-fw pi-users', to: 'users'},
                {label: 'DAM info', icon: 'pi pi-fw pi-image', to: 'dam'},
                {label: 'Jira Issues', icon: 'pi pi-fw pi-ticket', to: 'jira'},
                {label: 'Commerce Metrics', icon: 'pi pi-fw pi-briefcase', to: 'commerce'}
            ]
        }
    },
    mounted() {
        this.Account = this.$router.currentRoute.value.params.account
    }
}
</script>