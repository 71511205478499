<template>
  <Header account="Users">
    <Button @click="$router.push({name:'new-user'})" icon="pi pi-user-plus" label="Add user"/>
  </Header>
  <router-view />


</template>

<script>
import Header from "@/components/common/Header";

export default {
  name: "Users",
  components:{Header}
}
</script>