<template>
  <Toast/>

  <template v-if="isLogin">
    <Menubar>
      <template #start>
        <Button label="Home" @click="$router.push({name:'home'})" class="p-as-center back p-button-link" icon="pi pi-home" iconPos="left"/>
        <span class="user-info">
          {{ getUser().DisplayName }} ({{getUser().Email}})
          <span class="superuser" v-if="getUser().Superuser">Admin</span>
        </span>
      </template>
      <template #end>

        <Button label="Admin area" v-if="getUser().Superuser" @click="$router.push({name:'users'})" class="p-button-link"/>
        <Button label="Exit" @click="$router.push({name:'logout'})" class="p-button-link"/>

      </template>
    </Menubar>
    <main>
      <router-view/>
    </main>
  </template>

  <Login v-else />

</template>

<script>
import Menubar from "primevue/menubar"
import Login from "@/views/Login";
import Toast from "primevue/toast"

export default {
  components: {Menubar, Login, Toast},
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    this.isLogin = this.isLoginFunc()
    this.emitter.on('logout', () => {this.isLogin = false})
    this.emitter.on('login', ()=> {this.isLogin = true})
  },

}
</script>

<style>
body, html {
    padding:0;
    margin:0;
    font-size: 10pt;
    height:100%;
}

h3 {
    margin-left: 10px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: dimgray;
    /*margin: 5px;*/
    height:100%;
}

span.truncator {
    width:100%;
    display:inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.p-tooltip {
    max-width: initial !important;
}

.p-datatable-header {
    font-weight: inherit !important;
}


.details {
    font-size:10pt;
    background-color: white;
}
.details tr{
    border-bottom:1px solid lightgray;
}
.details tr td {
    padding:10px;
}
.details tr td:first-child {
    font-weight:bold;
    width:200px;
    background-color: #f1f1f1;
}

.p-progress-spinner {
    display:block;
}

.p-sidebar {
    overflow:auto;
}

.user-info {
  padding: 0.5rem 1rem;
  display: inline-block;
}

.user-info .superuser {
  background-color: #ffda1e;
  color:dimgray;
  padding:3px;
  font-size:9pt;
  border-radius: 3px;
}


</style>
