<template>
    <div v-if="initialized">
        
        <div class="p-grid header">
            <div class="p-col-6">
                <h1>{{ TotalRecords }} records</h1>
            </div>
            <div class="p-col-6">
                <Button type="Button" icon="pi pi-ticket" style="margin-right: 5px" class="p-button-info" @click="$router.push({name:'globaljira'})" label="Global Jira" />
                <Button type="Button" icon="pi pi-users" style="margin-right: 5px" class="p-button-secondary" @click="$router.push({name:'globalusers'})" label="Global users" />
                <Button type="Button" icon="pi pi-chart-bar" class="p-button-success" @click="$router.push({name:'globalcharts'})" label="Global metrics" />
            </div>
        </div>
        <DataTable
            v-if="Columns"
            :rowHover="true"
            responsiveLayout="scroll"
            :loading="loading"
            :value="Data"
            :rows="LazyFilters.perPage"
            :first="LazyFilters.first"
            :sortField="LazyFilters.sortField"
            :sortOrder="LazyFilters.sortOrder"
            :paginator="true"
            :lazy="true"
            :totalRecords="TotalRecords"
            showGridlines
            @page="onPage($event)"
            @sort="onPage($event)"
            v-model:filters="LazyFilters.filters"
            filterDisplay="row"
        >
            <template #header>
                <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
            </template>
            <template #loading>
                Loading...
            </template>
            <template #empty>
                No records found.
            </template>

            <Column :reorderableColumn="false"  headerStyle="width: 3rem">
                <template #body="slotProps">
                    <Button icon="pi pi-search" iconPos="right" class="p-button-secondary"  @click="navigate($event, slotProps.data.AccountShortname.Value)"/>
                </template>
            </Column>
            
            <Column v-for="col of Columns" :sortable="true"  :field="col.key" style="min-width:12rem"  :header="col.label" :key="col.key" :showFilterMenu="false">
                 <template #filter>
                    <InputText type="text" v-model="Filters[col.key]" @keydown.enter="onFilter($event)" class="p-column-filter"/>
                </template>

                <template #body="props">
                    <span :class="(col.key == 'AccountShortname')?'frozen':''">
                        {{ printFormat(props.data[col.key].Type, props.data[col.key].Value) }}

                        <span v-if="props.data[col.key].Delta != 0" class="delta">
                            <span :class="props.data[col.key].Delta > 0 ?'pos':'neg'">
                                    {{ printFormat(props.data[col.key].Type, props.data[col.key].Delta, true) }}
                            </span>
                        </span>
                    </span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MetricService from "@/services/metrics";
// import InputText from 'primevue/inputtext';

export default {
    name: 'MetricsTable',
    components: {
        DataTable,
        Column,
        // InputText,
    },
    props:['filters'],
    emits:['changed'],
    service: null,
    data() {
        return {
            initialized: false,
            Data: [],
            Columns:null,
            Filters:{},
            loading: false,
            TotalRecords: null,
            LazyFilters: null,
        }
    },
    watch: {
        filters: {
            handler(v) {
                this.LazyFilters = v
                this.Filters = {...v.filters}
                this.initialized = true
            },
            deep:true,
        },

        LazyFilters: {
            handler() {
                this.loadMetrics()
                this.$emit('changed', this.LazyFilters)
            },
            deep:true,
        }

    },
    created() {
        this.service = new MetricService();
    },
    methods: {
        exportCSV() {
            this.service.exportData(this.LazyFilters)
        },
        onFilter() {
            this.LazyFilters.filters = {...this.Filters}
        },
        onPage(event) {
            Object.assign(this.LazyFilters, {
                first: event.first,
                page: event.page,
                perPage: event.rows,
                pageCount: event.pageCount,
                sortField: event.sortField,
                sortOrder: event.sortOrder
            })
        },
        navigate(event, a) {
            this.$router.push({name: 'pim_overview', params:{account:a}})
        },
        loadMetrics() {
            this.loading = true;
            this.service.getMetrics(this.LazyFilters).then(data => {
              // console.log(data)
                this.Columns = this.LazyFilters.fields
            //   console.log(this.Columns)
                this.Data = data.total > 0 ? data.result : [];
                this.TotalRecords = data.total;

                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.header {
    margin:6px;
    margin-bottom: 0;
}

.header div:last-child {
    text-align: right;
}


.header h1 {
    font-size: 14pt;
    padding:0;
    margin:0;
}

.delta .pos {
    color:green;
}
.delta .neg {
    color: red;
}
.frozen {
    font-weight:bold;
}
</style>
