<template>
  <div class="p-d-flex p-jc-center p-ai-center">
    <div style="margin-top: 200px; min-width: 400px" class="p-p-4 p-shadow-2">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid">
          <div class="p-field">
            <label for="email">Email</label><br/>
            <InputText id="email" v-model="Email" type="text" placeholder="Email"/>
          </div>
          <div class="p-field">
            <label for="password">Password</label><br/>
            <InputText id="password" v-model="Password" type="password" placeholder="password"/>
          </div>
          <Message severity="warn" :life="5000" :key="ts" :sticky="true" v-if="lastError">{{ lastError }}</Message>
          <Button type="submit" label="Login"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import UACService from "@/services/uac";
import Message from "primevue/message";

export default {
  name: "Login",
  components: {Message,},
  service: null,
  data() {
    return {
      ts: 0,
      lastError: null,
      Email: null,
      Password: null
    }
  },
  created() {
    this.service = new UACService();
  },
  methods: {
    handleSubmit() {
      this.service.Login(this.Email, this.Password).then(data => {
        if (data.message !== "ok") {
          this.ts++;
          this.lastError = data.message
        } else {
          localStorage.AuthToken = data.token
          localStorage.User = JSON.stringify(data.user)
          this.emitter.emit("login");
          this.$router.push("/")
        }
      })
    }
  }
}
</script>