import axios from 'axios';

export default class UACService {
    Login(email, password) {
        return axios.get('/api/login', {params:{email:email, password:password}})
            .then(response => response.data)
    }
    Logout() {
        return axios.get('/api/logout')
            .then(response => response.data)
    }
    getUsers() {
        return axios.get('/api/uac', {})
            .then(response => response.data)
    }
    saveUser(data, userid) {
        if (userid===undefined) {
            return axios.post('/api/uac', data)
                .then(response => response.data)
        } else {
            return axios.put('/api/uac/'+userid, data)
                .then(response => response.data)
        }
    }
    deleteUser(userid) {
        return axios.delete('/api/uac/'+userid)
            .then(response => response.data)
    }
    getUser(userid) {
        return axios.get('/api/uac/'+userid)
            .then(response => response.data)
    }
}
