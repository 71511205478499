<template>
  <div class="checkboxlist">
    <div style="padding:50px 0 0 0" v-if="!Ready">
      <ProgressSpinner/>
    </div>

    <template v-else>
      <div class="deltabox searchbox">
        <Dropdown placeholder="Show delta" :options="DeltaOptions" optionLabel="label" optionValue="value"
                  v-model="Delta"/>
      </div>

      <div class="searchbox">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText type="text" v-model="SearchMetricStr" placeholder="Search metric"/>
            </span>
      </div>

      <Accordion v-for="c in Metrics" v-bind:key="c.label">
        <AccordionTab  :key="c.label" :disabled="SearchMetricStr && !VisibleGroups.includes(c.label)">
          <template #header>
            <span>{{ c.label }}</span>
            <i class="pi pi-star" v-if="getFiller(c)"></i>
          </template>

          <template v-for="i in c.items" v-bind:key="i.key">
            <div class="p-field-checkbox" v-if="!SearchMetricStr || VisibleMetrics.includes(i.key)">
              <Checkbox name="columns" :value="i" v-model="ActiveMetrics" :id="i.key"
                        :disabled="i.key === 'AccountShortname'"/>
              <label :for="i.key" v-bind:key="i.key">
                {{ i.label }}
              </label>
            </div>
          </template>
        </AccordionTab>
      </Accordion>
    </template>
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Checkbox from "primevue/checkbox";
import ProgressSpinner from 'primevue/progressspinner';
import MetricService from "@/services/metrics";

const defaultFields = ['AccountShortname', 'AccountName', 'AccountHost', 'Platform']

const LC_FIELDS = "c-c-fields"
const LC_ACTIVE_FIELDS = "c-c-fields-active"
const LC_DELTA = "c-c-fields-delta"
// const TTL_CACHE = 1000


export default {
  name: "Navigation",
  components: {AccordionTab, Accordion, Checkbox, ProgressSpinner},
  service: null,
  emits: ['changed', 'ready'],
  created() {
    this.service = new MetricService();
  },
  mounted() {
    this.getMetrics()
  },
  data() {
    return {
      Ready: false,
      Metrics: [],
      Delta: 7,
      ActiveMetrics: [],
      DeltaOptions: [
        {value: 0, label: "No delta"},
        {value: 1, label: "1 Day delta"},
        {value: 2, label: "2 Day delta"},
        {value: 3, label: "3 Day delta"},
        {value: 4, label: "4 Day delta"},
        {value: 7, label: "1 Week delta"},
        {value: 14, label: "2 Week delta"},
        {value: 30, label: "30 Days delta"},
        {value: 60, label: "60 Days delta"},
      ],
      SearchMetricStr: '',
      VisibleGroups: [],
      VisibleMetrics: [],
    }
  },
  methods: {
    getFiller(c) {
      let keys = this.ActiveMetrics.map(e => e.key)
      let ret = false
      c.items.forEach(e => {
        if (keys.includes(e.key)) {
          ret = true
        }
      })
      return ret
    },
    async getMetrics() {
      // let needUpdateCache = true

      // if (localStorage.getItem(LC_FIELDS)) {
        // const fieldsCache = JSON.parse(localStorage.getItem(LC_FIELDS))
        // const difference = Math.ceil((new Date() - new Date(fieldsCache.TS)) / 1000)
        // if (difference < TTL_CACHE) {
          // this.Metrics = fieldsCache.Metrics
          // needUpdateCache = false
        // }
      // }

      // if (needUpdateCache) {
        this.Metrics = await this.service.getFields();
        localStorage.setItem(LC_FIELDS, JSON.stringify({Metrics: this.Metrics, TS: new Date().toJSON()}))
      // }

      this.setActive()
      this.setDelta()
      this.Ready = true
    },
    setDelta() {
      this.Delta = parseInt(localStorage.getItem(LC_DELTA))
      if (isNaN(this.Delta)) {
        this.Delta = 7
      }
    },
    setActive() {
      this.ActiveMetrics = JSON.parse(localStorage.getItem(LC_ACTIVE_FIELDS))
      if (this.ActiveMetrics === null) {
        this.ActiveMetrics = []
        this.Metrics.forEach(v1 => {
          v1.items.forEach(v2 => {
            if (defaultFields.includes(v2.key)) this.ActiveMetrics.push(v2)
          })
        })
      }
    },
    prepData: function () {
      return {delta: this.Delta, metrics: this.ActiveMetrics}
    }
  },
  watch: {
    Delta: function (v) {
      if (this.Ready) {
        localStorage.setItem(LC_DELTA, v)
        this.$emit('changed', this.prepData())
      }
    },
    ActiveMetrics: function (v) {
      if (this.Ready) {
        localStorage.setItem(LC_ACTIVE_FIELDS, JSON.stringify(v))
        this.$emit('changed', this.prepData())
      }
    },
    SearchMetricStr: function (v) {
      if (v.length === 0) return
      this.VisibleMetrics = [];
      this.VisibleGroups = [];
      v = v.toLowerCase();
      this.Metrics.forEach(g => {
        g.items.forEach(e => {
          if (e.key.toLowerCase().includes(v)) {
            this.VisibleMetrics.push(e.key);
            this.VisibleGroups.push(g.label);
          }
        })
      })
    },
  },
}
</script>

<style scoped>
.checkboxlist {
  height: 100vh;
  background-color: #d9f0f6;
  overflow-y: auto;
}

.checkboxlist .searchbox {
  width: 100%;
  background-color: #9ebac1;
  padding: 10px;
}

.checkboxlist .searchbox .p-input-icon-left input {
  width: 100%;
}

.checkboxlist .searchbox .p-input-icon-left {
  width: 100%;
}

.checkboxlist-group b {
  display: block;
  margin-bottom: 10px;
}

.checkboxlist .deltabox {
  margin-bottom: 5px;
}

.checkboxlist .deltabox .p-dropdown {
  width: 100%
}

.pi-star {
  margin-left: 10px;
  color: #89aaee;
}

</style>
