<template>
    <Header>
        <Button icon="pi pi-external-link" label="Export" @click="exportCSV"/>
    </Header>

    <div class="p-grid">

        <div class="p-col-12" style="height:300px">
            <h3>Created per month</h3>
            <Chart class="created-chart" :height="250" type="bar" :data="Charts.Created" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </div>
        <div class="p-col-3">
            <h3>Per project</h3>
            <Chart type="pie" :data="Charts.Project" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </div>
        <div class="p-col-3">
            <h3>Per status</h3>
            <Chart type="pie" :data="Charts.Status" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </div>
        <div class="p-col-3">
            <h3>Per type</h3>
            <Chart type="pie" :data="Charts.IssueTypeS" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </div>
        <div class="p-col-3">
            <h3>Per Module</h3>
            <Chart type="pie" :data="Charts.Module" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </div>

    </div>
    <DataTable
        :value="Tasks" :paginator="true" :rows="LazyFilters.Limit" :loading="Loading"
        @page="onPage($event)"
        @sort="onPage($event)"
        v-model:filters="LazyFilters.Filters"
        filterDisplay="row" :lazy="true"
        :rowHover="true"
        :totalRecords="TotalRecords"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        v-model:expandedRows="ExpandedRows"
    >
        <template #header>
            <h2 class="p-m-0">{{TotalRecords}} tasks</h2>
        </template>

        <Column :expander="true" headerStyle="width: 3rem"/>
        <Column :sortable="true" field="Project" style="width:12rem" header="Project" :showFilterMenu="false">
            <template #filter>
                <Dropdown :showClear="true" :options="Options.Projects" v-model="LazyFilters.Filters.Project"
                          @change="onFilter($event)" class="p-column-filter"/>
            </template>
        </Column>

        <Column :sortable="true" field="TypeShort" style="width:20rem" header="Type Short" :showFilterMenu="false">
            <template #filter>
                <MultiSelect :options="Options.TypesShort" v-model="LazyFilters.Filters.TypeShort"
                             @change="onFilter($event)" class="p-column-filter"/>
            </template>
            <template #body="s">
                <span v-tooltip.bottom="s.data.TypeShort" class="truncator">{{ s.data.TypeShort }}</span>
            </template>

        </Column>

        <Column :sortable="true" field="Type" style="width:20rem" header="Type" :showFilterMenu="false">
            <template #filter>
                <MultiSelect :options="Options.Types" v-model="LazyFilters.Filters.Type" @change="onFilter($event)"
                             class="p-column-filter"/>
            </template>
            <template #body="s">
                <span v-tooltip.bottom="s.data.Type" class="truncator">{{ s.data.Type }}</span>
            </template>

        </Column>

        <Column :sortable="true" field="Status" style="width:10rem" header="Status" :showFilterMenu="false">
            <template #filter>
                <MultiSelect :options="Options.Status" v-model="LazyFilters.Filters.Status" @change="onFilter($event)"
                             class="p-column-filter"/>
            </template>
        </Column>

        <Column field="Summary" header="Summary" :showFilterMenu="false">
            <template #filter>
                <InputText type="text" v-model="LazyFilters.Filters.Summary" @keydown.enter="onFilter($event)"
                           class="p-column-filter"/>
            </template>
        </Column>


        <Column :sortable="true" field="Created" style="width:10rem" header="Created">
            <template #body="s">
                {{ printFormat('time', s.data.Created) }}
            </template>
        </Column>

        <Column :sortable="true" field="Updated" style="width:10rem" header="Updated" :showFilterMenu="false">
            <template #body="s">
                {{ printFormat('time', s.data.Updated) }}
            </template>
        </Column>

        <template #expansion="slotProps">
            <div class="p-grid">
                <div class="p-col-9">
                    <table class="details">
                        <tr v-for="(v,k) in slotProps.data" v-bind:key="k">
                            <td>{{ k }}</td>
                            <td v-if="k==='Key'"><a target="_blank" :href="'https://brandquad.atlassian.net/browse/'+v">{{ v }}</a>
                            </td>
                            <td v-else-if="k==='Body'" v-html="v.replace(/([^>])\n/g, '$1<br/>')"></td>
                            <td v-else>{{ printArray(v) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>

    </DataTable>

</template>

<script>
import Header from "@/components/common/Header";
import MetricService from "@/services/metrics";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chart from "primevue/chart";
import ProgressSpinner from "primevue/progressspinner";
// import 'chartjs-plugin-colorschemes';


export default {
    name:"GlobalJira",
    components: {Header, DataTable, Column, MultiSelect, Chart, ProgressSpinner},
    service: null,
    data: function(){
        return {
            Charts: {
                Created: {Name:""},
                Project: {Name:""},
                Status: {Name:""},
                IssueTypeS: {Name:""},
                Module: {Name:""},
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {duration: 0},
                legend: {display: false},
                plugins: {
                    // colorschemes: {
                        // scheme: 'brewer.Paired12'
                    // }
                }
            },
            ExpandedRows: null,
            Loading: false,
            TotalRecords: null,
            Filters: {},
            Options: {},
            LazyFilters: {
                Fields: [],
                Offset: 0,
                page: 1,
                Limit: 20,
                SortField: null,
                SortOrder: null,
                Filters: {},
                delta: 0,
            },
            Tasks: null,
        }
    },
    created() {
        this.service = new MetricService();
        this.loadCharts();
        this.loadData();
    },
    mounted() {
    },
    methods: {
        loadCharts() {
            for (let [key, ] of Object.entries(this.Charts)) {
                this.service.getJiraCharts(this.LazyFilters, this.Account, key).then(data => {
                    let l = []
                    if (key === "Created" || key === "Updated" ) {
                        data.XAxis.forEach(ll => {
                            l.push(new Date(ll).toLocaleDateString())
                        })
                    } else {
                        l = data.XAxis
                    }
                    let d = data.YAxis

                    this.Charts[key] ={
                        Name:data.Name,
                        labels: l,
                        datasets: [{borderWidth: 0, data: d}]}
                })

            }
        },
        loadData() {
            this.Loading = true;
            this.service.getJira(this.LazyFilters, "").then(data => {
                this.Tasks = data.data;
                this.Options.Projects = data.projects;
                this.Options.Types = data.types;
                this.Options.TypesShort = data.typesShort;
                this.Options.Status = data.status;
                this.TotalRecords = data.total;
                this.Loading = false;
            })
        },
        onFilter() {
            this.loadCharts();
            this.loadData();

        },
        exportCSV() {
            this.service.exportJira(this.LazyFilters, "")
        },
        onPage(event) {
            Object.assign(this.LazyFilters, {
                Offset: event.first,
                page: event.page,
                Limit: event.rows,
                pageCount: event.pageCount,
                SortField: event.sortField,
                SortOrder: event.sortOrder
            })
            this.loadCharts();
            this.loadData();
        }
    }
}
</script>
