<template>
    <Header />
    <div class="p-grid">

        <div class="p-col-6" v-for="chart in Charts" :key="chart">
            <Chart type="bar" :data="chart" :options="chartOptions" />
        </div>

    </div>

</template>

<script>
import Header from "@/components/common/Header";
import MetricService from "@/services/metrics";
import Chart from "primevue/chart";
// import 'chartjs-plugin-colorschemes';

export default {
    components: {Header, Chart},
    name:"GlobalCharts",
    service: null,
    data: function(){
        return {
            chartsTypes: ["Products", "ProductsComments", "TotalAttributes", "Validators", "CalculatedAttributes", "TotalRules", "Clusters", "ActiveUsers", "ClientUsers", "Roles", "Files", "FilesSize", "Folders", "DAM2PIM", "TotalValuesLists", "CleanMails", "ECISpidersTotal", "ECIBrandsTotal", "ECIComparators", "ECISkuTotal", "ECIRpcTotal", "ECILinks", "Files", "ActiveChannelsV1", "ChannelsV2Active"],
            Charts: [],
            chartOptions: {
                animation: {duration: 0},
                plugins: {
                    // colorschemes: {
                        // scheme: 'brewer.Paired12'
                    // }
                }
            },
        }
    },
    created() {
        this.service = new MetricService();
    },
    mounted() {
        this.chartsTypes.forEach(c => {
            this.service.getGlobalCharts(c).then(data => {
                let c = {labels: data.XAxis, datasets: [{label:data.Name, fill: true,  data:data.YAxis}]}
                this.Charts.push(c)
            })
        })
    }
}
</script>
