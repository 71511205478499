import axios from 'axios';

export default class MetricService {

    getAccounts() {
        return axios
            .get('api/accounts')
            .then(response => response.data)
    }

    getFields() {
        return axios
            .get('api/fields')
            .then(response => response.data)
    }

    getOverviewEci(account, delta) {
        return axios
            .get('api/metrics/eci', {params: {account:account, delta:delta}})
            .then(response => response.data)
    }

    getListsEci(account) {
        return axios
            .get('api/metrics/eci/lists', {params: {account:account}})
            .then(response => response.data)
    }

    getOverviewPim(account, delta) {
        return axios
            .get('api/metrics/pim', {params: {account:account, delta:delta}})
            .then(response => response.data)
    }

    getMetrics(lazyFilters) {
        let clone = {...lazyFilters};
        clone.fields = clone.fields.map(x => x.key);

        return axios
            .post('api/metrics', clone)
            .then(response => response.data)
    }

    getMetricDetail(account, metric) {
        return axios
            .post('api/metrics/details', {account:account, metric:metric})
            .then(response => response.data)
    }

    getUsers(filters) {
        return axios
            .post('api/users', filters)
            .then(response => response.data)
    }
    getUserCharts(account, type) {
        return axios
            .post('api/users/charts', {}, {params:{account:account, type:type}})
            .then(response => response.data)
    }

    getJira(filters, account) {
        return axios
            .post('api/jira', filters, {params:{account:account}})
            .then(response => response.data)
    }

    getGlobalUsers(filters) {
        return axios
            .post('api/users/global', filters)
            .then(response => response.data)
    }

    getDamMimetypes(account) {
        return axios
            .get('api/dam/mimetypes', {params:{account:account}})
            .then(response => response.data)
    }

    getDamTop300(account) {
        return axios
            .get('api/dam/top300', {params:{account:account}})
            .then(response => response.data)
    }

    getDamChart(account, type) {
        return axios
            .get('api/dam/charts', {params:{account:account, type:type}})
            .then(response => response.data)
    }

    getGlobalCharts(chartType) {
        return axios
            .get('api/globalcharts', {params: {type:chartType}})
            .then(response => response.data)
    }

    getJiraCharts(filters, account, chartType) {
        return axios
            .post('api/jira/charts', filters,{params: {type:chartType, account:account}})
            .then(response => response.data)
    }

    exportGlobalUsers(filters) {
        return axios
            .post('api/users/global/export', filters, {responseType: 'blob' })
            .then(res =>
            {
                let blob = new Blob([res.data], { type: res.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res.headers['filename']
                link.click()
            })
    }
    exportJira(filters, account) {
        return axios
            .post('api/jira/export', filters, {responseType: 'blob', params:{account:account}})
            .then(res =>
            {
                let blob = new Blob([res.data], { type: res.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res.headers['filename']
                link.click()
            })
    }
    exportUsers(filters) {
        return axios
            .post('api/users/export', filters, {responseType: 'blob' })
            .then(res =>
            {
                let blob = new Blob([res.data], { type: res.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res.headers['filename']
                link.click()
            })
    }
    exportData(lazyFilters) {
        let clone = {...lazyFilters};
        clone.fields = clone.fields.map(x => x.key);

        return axios
            .post('api/metrics/export', clone, { responseType: 'blob' })
            .then(res =>
            {
                let blob = new Blob([res.data], { type: res.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res.headers['filename']
                link.click()
            })
    }
}