<template>
    <div class="p-grid" v-if="isLogged">
        <div class="p-col-2 p-p-1" style="padding-top: 7px !important;">
            <Navigator :metrics="Metrics" :delta="LazyFilters.delta" :active="LazyFilters.fields" @changed="onMetricChanged" />
        </div>
        <div class="p-col-10 p-p-0">
            <MetricsTable :filters="LazyFilters" @changed="onFilterChanged" />
        </div>
    </div>
</template>

<script>

import Navigator from "@/components/dashboard/Navigator"
import MetricsTable from "@/components/dashboard/MetricsTable";
import MetricService from "@/services/metrics";

const LC_KEY = "c-c-filters"

export default {
    components: {Navigator, MetricsTable},
    name:"Dashboard",
    service: null,
    data: function(){
        return {
            Loaded:false,
            Metrics:[],
            LazyFilters: {
                fields: [],
                first: 0,
                page: 1,
                perPage: 20,
                sortField: null,
                sortOrder: null,
                filters: {},
                delta: 7,
            },
        }
    },
    methods:{
        onMetricChanged: function({delta, metrics}) {
            if (this.Loaded == true) {
                this.LazyFilters.fields = metrics
                this.LazyFilters.delta = delta
            }
        },
        onFilterChanged: function(filters) {
            localStorage.setItem(LC_KEY, JSON.stringify(filters))
        }
    },
    created() {
        this.service = new MetricService();
    },
    computed: {
      isLogged: function () {
        return localStorage.AuthToken===undefined?false:true
      },
    },
    mounted() {
        this.service.getFields().then(data => {
            this.Metrics = data;

            const ac = localStorage.getItem(LC_KEY);

            if  (ac != null) {
                this.LazyFilters = JSON.parse(ac);
            }

            this.Loaded = true
        })
    }
}
</script>
