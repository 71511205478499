import { createApp } from 'vue'
import { router } from './router'
import axios from 'axios'
import mitt from 'mitt'

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';
import App from './App.vue'
import Button from "primevue/button";
import Tooltip from 'primevue/tooltip';
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import prettyBytes from "pretty-bytes";

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter;

app.mixin(
    {
        methods: {
            isLoginFunc() {
                return (localStorage.User===undefined) ? false : true;
            },
            getUser() {
                if (localStorage.User) {
                    return JSON.parse(localStorage.User)
                } else return {}
            },
            getColor(index) {
                const pallete = ['#439b43', '#c66666', '#49a9db','#49a9db', '#58b4dd', '#68bcdd', '#78c3db', '#87c7d8', '#97c9d3', '#a6c8cd', '#b4c5c5', '#c2c0bc', '#cfb8b1', '#daada5', '#e49f98', '#ed8e8a', '#f4797a', '#f95d6a']
                var remainder = index % pallete.length;

                return pallete[remainder === 0 ? pallete.length - 1 : remainder - 1];
            },
            hexToRgbA(hex){
                var c;
                if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                    c = hex.substring(1).split('');
                    if(c.length === 3){
                        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                    }
                    c= '0x'+c.join('');
                    return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
                }
                throw new Error('Bad Hex');
            },
            printArray(value) {
                if (typeof value == 'object' && value != null) {
                    return value.join(", ")
                }
                return value
            },
            printFormat(type, data, is_delta) {
                if (type === "datetime") {
                    const d = new Date(data);
                    return d.toLocaleDateString() +' ' + d.toLocaleTimeString();
                }
                if (type === "time") {
                    return new Date(data).toLocaleDateString();
                }

                if (type === "filesize") {
                    if (is_delta === true) {
                        return (data>0?"+":"") + prettyBytes(data);
                    }
                    return prettyBytes(data);
                }
                if (type === "int") {
                    if (is_delta === true) {
                        return (data>0?"+":"") + data
                    }
                    return data

                }
                return data
            },
        }
    }
)
app.directive('tooltip', Tooltip)
app.component("Button", Button)
app.component("Dropdown", Dropdown)
app.component("InputText", InputText)
app.component("Toast", Toast)

app.use(ConfirmationService);
app.use(router)
app.use(PrimeVue, {ripple: true})
app.use(ToastService)
app.mount('#app')

axios.interceptors.request.use((request) => {
    request.headers['X-Auth-Token'] = localStorage.AuthToken
    return request
})

axios.interceptors.response.use((response) => {
        return response
    },
    function (error) {
        if (error.response.status === 403) {
            router.push({name: "login"})
            return false
        }
        return Promise.reject(error);
    })


if (process.env.VUE_APP_ENV_DEBUG === "1") {
    axios.defaults.baseURL = "http://localhost:9090"
} else {
    axios.defaults.baseURL = "/"
}
